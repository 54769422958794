import { IMG_URL } from 'configs/app-global';
import placeholder from '../assets/images/placeholder.jpeg';

export default function getImage(img) {
  if (!img) {
    return placeholder;
  }

  const hasUrl = img.includes("http://") || img.includes("https://");
  return hasUrl ? img : IMG_URL + img;
}


export function treatImage(data, property = '') {
  
  const exec = (data)=>{

    if(property && data[property]){
      data[property] = getImage(data[property]) ;
    }
  
    if(data.img){
      data.img = getImage(data.img) 
    }
    if(data.logo_img){
      data.logo_img = getImage(data.logo_img) 
    }
    if(data.background_img){
      data.background_img = getImage(data.background_img) 
    }
  
    if(data.bg_img){
      data.bg_img = getImage(data.bg_img) 
    }
  }

  exec(data);

  if(data.children?.length > 0){
    data.children?.map(item=>{
      exec(item);
      return item;
    });
  }

}