
export const PROJECT_NAME = 'Portal Rebbity';
export const production = true;
export const BASE_URL = 
// 'https://api.rebbity.com';
  production ? (process.env.REACT_APP_BASE_URL || 'https://api.rebbity.com') : (process.env.REACT_APP_BASE_URL || 'http://localhost:8090');
// export const REACT_APP_BASE_URL =   'http://localhost:8090'
export const WEBSITE_URL = 'https://rebbity.com';
export const api_url = BASE_URL + '/api/v1/';
export const api_url_admin = BASE_URL + '/api/v1/dashboard/admin/';
export const api_url_admin_dashboard = BASE_URL + '/api/v1/dashboard/';
export const IMG_URL = BASE_URL + '/storage/images/';
export const MAP_API_KEY = 'AIzaSyBxRCNzGL2HvAZo2b073thss376BawndFA';
export const export_url = BASE_URL + '/storage/';
export const example = BASE_URL + '/';

export const VAPID_KEY = 'BF10yYpb8CrQb-2dSEMzm_w-h5AnkdvVWXjw-fvbXV2MDdCi_iAA42atQebL1D7zWhugSy9TTkIe3YxsmYT6yIs';

export const LAT = -47.9292 
export const LNG = -15.7801;

// export const API_KEY = 'AIzaSyCitsCbMgiTgdeHYltxbMZaJBOtv_8axXM';
// export const AUTH_DOMAIN = 'rebbify-83ca5.firebaseapp.com"';
// export const PROJECT_ID = 'rebbify-83ca5';
// export const STORAGE_BUCKET = 'rebbify-83ca5.appspot.com';
// export const MESSAGING_SENDER_ID = '115452835699';
// export const APP_ID = '1:115452835699:web:63dad7edac53924391f706';
// export const MEASUREMENT_ID = 'G-LPKL9NJFH0';


export const API_KEY = 'AIzaSyD8qdHOmnV7_vECdNecFLtqOh1Etx3pXWI';
export const AUTH_DOMAIN = 'rebbify-taxi.firebaseapp.com';
export const PROJECT_ID = 'rebbify-taxi';
export const STORAGE_BUCKET = 'rebbify-taxi.appspot.com';
export const MESSAGING_SENDER_ID = '724817576648';
export const APP_ID = '1:724817576648:web:8bb2654e916a35812b9bfd';
export const MEASUREMENT_ID = 'G-LPKL9NJFH0';


/* export const API_KEY = 'AIzaSyDgBdfkn1kH_xJand9MWUcRt-X214UiO1o';
export const AUTH_DOMAIN = 'rebbity-ff1f7.firebaseapp.com"';
export const PROJECT_ID = 'rebbity-ff1f7';
export const STORAGE_BUCKET = 'rebbity-ff1f7.appspot.com';
export const MESSAGING_SENDER_ID = '902131295771';
export const APP_ID = '1:902131295771:web:34e9a39375c0af6203fabe';
export const MEASUREMENT_ID = 'G-N4VXXYDH6G'; */

// #Local
// export const RECAPTCHASITEKEY = '6LesdI0mAAAAAOsL4S5ZsqGmaW5VTw6z8bc7_maZ';

// #prod
export const RECAPTCHASITEKEY = production ? '6Lexx7IpAAAAAARyRvKP0vMKL4u3zK0sA1QR6aku' : '6LesdI0mAAAAAOsL4S5ZsqGmaW5VTw6z8bc7_maZ';

export const DEMO_SELLER = 334; // seller_id
export const DEMO_SELLER_UUID = '3566bdf6-3a09-4488-8269-70a19f871bd0'; // seller_id
export const DEMO_SHOP = 599; // seller_id
export const DEMO_DELIVERYMAN = 375; // deliveryman_id
export const DEMO_MANEGER = 114; // deliveryman_id
export const DEMO_MODERATOR = 297; // deliveryman_id
export const DEMO_ADMIN = 107; // deliveryman_id

export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/svg',
];
