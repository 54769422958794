import { IMG_URL } from '../configs/app-global';
import avatar from '../assets/images/1.png';

export default function getAvatar(url) {
  if (!url) {
    return avatar;
  }

  const hasUrl = url.includes("http://") || url.includes("https://");
  return hasUrl ? url : IMG_URL + url;
}
