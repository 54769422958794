import treatResponse from 'helpers/treatResponseData';
import request from './request';
import requestWithoutTimeout from './requestWithoutTimeout';

const categoryService = {
  getAll: (params) =>
    treatResponse(request.get('dashboard/admin/categories/paginate', { params })),
  getAllMain: (params) => treatResponse(request.get('dashboard/admin/categories', { params })),
  selectPaginate: (params) =>
    treatResponse(request.get('dashboard/admin/categories/select-paginate', { params })),
  getById: (id, params) =>
    treatResponse(request.get(`dashboard/admin/categories/${id}`, { params })),
  create: (params) =>
    request.post('dashboard/admin/categories', {}, { params }),
  update: (id, params) =>
    request.put(`dashboard/admin/categories/${id}`, {}, { params }),
  delete: (params) =>
    request.delete(`dashboard/admin/categories/delete`, { params }),
  search: (params) =>
    treatResponse(request.get('dashboard/admin/categories/search', { params })),
  setActive: (id) => request.post(`dashboard/admin/categories/active/${id}`),
  dropAll: () => request.get(`dashboard/admin/categories/drop/all`),
  restoreAll: () => request.get(`dashboard/admin/categories/restore/all`),
  export: (params) =>
    requestWithoutTimeout.get('dashboard/admin/categories/export', { params }),
  import: (data) =>
    requestWithoutTimeout.post('dashboard/admin/categories/import', data),
  updatePosition: (uuid, data) =>
    request.post(`dashboard/admin/category-input/${uuid}`, data),
  paginateSelect: (params) =>
    request.get('dashboard/admin/categories/select-paginate', { params }),
};

export default categoryService;
