// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-tabs .tab-card {
  border-radius: 0 0.625rem 0.625rem 0.625rem; }

.order-tabs .tabs-container {
  position: relative;
  width: 100%;
  padding-right: 50px; }
  .order-tabs .tabs-container .tab-add-button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%); }

.order-tabs .tabs {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  bottom: -1px;
  z-index: 2;
  overflow-x: auto; }
  .order-tabs .tabs .tab {
    flex-shrink: 0;
    position: relative;
    padding: 16px;
    padding-bottom: 10px;
    background-color: transparent;
    opacity: 0.5;
    border: 1px solid transparent;
    border-bottom: 0;
    border-radius: 0.625rem 0.625rem 0 0;
    cursor: pointer; }
    .order-tabs .tabs .tab.active {
      background-color: #fff;
      opacity: 1;
      border-color: var(--border-color); }
    .order-tabs .tabs .tab .close-button {
      display: block;
      font-size: 10px;
      color: var(--dark);
      font-weight: 500;
      padding: 3px;
      margin: 0;
      border-radius: 50%; }
      .order-tabs .tabs .tab .close-button:hover {
        background-color: var(--sidebar-active); }
`, "",{"version":3,"sources":["webpack://./src/assets/scss/components/pos-tabs.scss"],"names":[],"mappings":"AAAA;EAEI,2CAA2C,EAAA;;AAF/C;EAKI,kBAAkB;EAClB,WAAW;EACX,mBAAmB,EAAA;EAPvB;IASM,kBAAkB;IAClB,QAAQ;IACR,QAAQ;IACR,2BAA2B,EAAA;;AAZjC;EAgBI,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,gBAAgB,EAAA;EArBpB;IAuBM,cAAc;IACd,kBAAkB;IAClB,aAAa;IACb,oBAAoB;IACpB,6BAA6B;IAC7B,YAAY;IACZ,6BAA6B;IAC7B,gBAAgB;IAChB,oCAAoC;IACpC,eAAe,EAAA;IAhCrB;MAkCQ,sBAAsB;MACtB,UAAU;MACV,iCAAiC,EAAA;IApCzC;MAuCQ,cAAc;MACd,eAAe;MACf,kBAAkB;MAClB,gBAAgB;MAChB,YAAY;MACZ,SAAS;MACT,kBAAkB,EAAA;MA7C1B;QA+CU,uCAAuC,EAAA","sourcesContent":[".order-tabs {\n  .tab-card {\n    border-radius: 0 0.625rem 0.625rem 0.625rem;\n  }\n  .tabs-container {\n    position: relative;\n    width: 100%;\n    padding-right: 50px;\n    .tab-add-button {\n      position: absolute;\n      top: 50%;\n      right: 0;\n      transform: translateY(-50%);\n    }\n  }\n  .tabs {\n    display: flex;\n    flex-wrap: nowrap;\n    position: relative;\n    bottom: -1px;\n    z-index: 2;\n    overflow-x: auto;\n    .tab {\n      flex-shrink: 0;\n      position: relative;\n      padding: 16px;\n      padding-bottom: 10px;\n      background-color: transparent;\n      opacity: 0.5;\n      border: 1px solid transparent;\n      border-bottom: 0;\n      border-radius: 0.625rem 0.625rem 0 0;\n      cursor: pointer;\n      &.active {\n        background-color: #fff;\n        opacity: 1;\n        border-color: var(--border-color);\n      }\n      .close-button {\n        display: block;\n        font-size: 10px;\n        color: var(--dark);\n        font-weight: 500;\n        padding: 3px;\n        margin: 0;\n        border-radius: 50%;\n        &:hover {\n          background-color: var(--sidebar-active);\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
