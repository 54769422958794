// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shop-add {
  background: var(--white); }
  .shop-add .bg-white {
    padding: 0 !important; }
`, "",{"version":3,"sources":["webpack://./src/assets/scss/components/restourant-add.scss"],"names":[],"mappings":"AAAA;EACE,wBAAwB,EAAA;EAD1B;IAGI,qBAAqB,EAAA","sourcesContent":[".shop-add {\n  background: var(--white);\n  .bg-white {\n    padding: 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
