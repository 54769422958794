// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-col-spin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh; }

.card-container {
  margin-top: 20px;
  max-width: 100%;
  width: 100%;
  justify-content: center; }
  .card-container .button {
    width: 150px; }
  .card-container .ant-row {
    width: 100%; }
    .card-container .ant-row .ant-col {
      width: 100%; }
      .card-container .ant-row .ant-col .ant-card {
        width: 100%; }
        .card-container .ant-row .ant-col .ant-card .ant-card-body {
          padding: 24px 10px 24px 10px; }
          .card-container .ant-row .ant-col .ant-card .ant-card-body .ant-card-meta-detail > div:not(:last-child) {
            font-size: 14px; }
          .card-container .ant-row .ant-col .ant-card .ant-card-body .ant-card-meta-description {
            color: var(--dark);
            font-size: 14px; }
        .card-container .ant-row .ant-col .ant-card .icon-center {
          position: absolute;
          top: 5px;
          right: 8px; }
    .card-container .ant-row .ant-row {
      width: 100%;
      display: flex;
      justify-content: center; }
`, "",{"version":3,"sources":["webpack://./src/assets/scss/components/product-categories.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY,EAAA;;AAGd;EACE,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,uBAAuB,EAAA;EAJzB;IAOI,YAAY,EAAA;EAPhB;IAUI,WAAW,EAAA;IAVf;MAaM,WAAW,EAAA;MAbjB;QAmCQ,WAAW,EAAA;QAnCnB;UAiBU,4BAA4B,EAAA;UAjBtC;YAoBY,eAAe,EAAA;UApB3B;YAwBY,kBAAkB;YAClB,eAAe,EAAA;QAzB3B;UA8BU,kBAAkB;UAClB,QAAQ;UACR,UAAU,EAAA;IAhCpB;MAwCM,WAAW;MACX,aAAa;MACb,uBAAuB,EAAA","sourcesContent":[".ant-col-spin {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 80vh;\n}\n\n.card-container {\n  margin-top: 20px;\n  max-width: 100%;\n  width: 100%;\n  justify-content: center;\n\n  .button {\n    width: 150px;\n  }\n  .ant-row {\n    width: 100%;\n\n    .ant-col {\n      width: 100%;\n\n      .ant-card {\n        .ant-card-body {\n          padding: 24px 10px 24px 10px;\n\n          .ant-card-meta-detail > div:not(:last-child) {\n            font-size: 14px;\n          }\n\n          .ant-card-meta-description {\n            color: var(--dark);\n            font-size: 14px;\n          }\n        }\n\n        .icon-center {\n          position: absolute;\n          top: 5px;\n          right: 8px;\n        }\n\n        width: 100%;\n      }\n    }\n\n    .ant-row {\n      width: 100%;\n      display: flex;\n      justify-content: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
