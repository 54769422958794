// ** React Imports
import { lazy } from 'react';

const UsersRoutes = [
  {
    path: 'shop-users/users',
    component: lazy(() => import('../seller/user')),
  },
  // {
  //   path: 'shop-users/users/user/:id',
  //   component: lazy(() => import('views/seller-views/user/user-detail')),
  // },
  // {
  //   path: 'shop-users/users/role',
  //   component: lazy(() => import('views/user/role-list')),
  // },
  {
    path: 'shop-users/user/add',
    component: lazy(() => import('views/seller-views/user/user-add')),
  },
];

export default UsersRoutes;
