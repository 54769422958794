// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-container {
  display: grid;
  grid-template-columns: 15% 85%;
  height: 100vh;
  position: relative;
  z-index: 1; }

.parcel-float {
  position: fixed;
  right: 85px;
  bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 50px;
  padding: 0 16px;
  z-index: 99;
  border-radius: 0.625rem;
  border: 1px solid var(--anchor-before-bg);
  background-color: #fff;
  box-shadow: 0 0 7px rgba(61, 61, 61, 0.2);
  cursor: default; }
  .parcel-float label {
    color: var(--body); }

[data-theme='dark'] .parcel-float {
  background: #4d5b75; }
`, "",{"version":3,"sources":["webpack://./src/assets/scss/page/dashboard.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,kBAAkB;EAClB,UAAU,EAAA;;AAGZ;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,WAAW;EACX,uBAAuB;EACvB,yCAAyC;EACzC,sBAAsB;EACtB,yCAAyC;EACzC,eAAe,EAAA;EAfjB;IAiBI,kBAAkB,EAAA;;AACtB;EAIE,mBAAmB,EAAA","sourcesContent":[".dashboard-container {\n  display: grid;\n  grid-template-columns: 15% 85%;\n  height: 100vh;\n  position: relative;\n  z-index: 1;\n}\n\n.parcel-float {\n  position: fixed;\n  right: 85px;\n  bottom: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: auto;\n  height: 50px;\n  padding: 0 16px;\n  z-index: 99;\n  border-radius: 0.625rem;\n  border: 1px solid var(--anchor-before-bg);\n  background-color: #fff;\n  box-shadow: 0 0 7px rgba(61, 61, 61, 0.2);\n  cursor: default;\n  label {\n    color: var(--body);\n  }\n}\n\n[data-theme='dark'] .parcel-float {\n  background: #4d5b75;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
