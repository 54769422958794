import { treatImage } from "./getImage";

export default async function treatResponse(request, properties = {}) {
  return new Promise(async (resolve, reject)=>{

    properties = properties || {};

    const servicesRouter = {
      shopService: treatShopData
    };

    request.then((res)=>{
      try{
        const isArray = res.data instanceof Array;

        // console.log(res.data);

        if(isArray){
          res.data = (res.data || []).map((item)=>{ 
            treatImage(item, properties.img);
            if(properties.service && servicesRouter[properties.service]){
              servicesRouter[properties.service](item);
            }
            return item;
          });
        }else{
          treatImage(res.data, properties.img);

          if(properties.service && servicesRouter[properties.service]){
            servicesRouter[properties.service](res.data);
          }
        }

        resolve(res);
      }catch(error){
        reject(error);
      }
    }).catch((error)=>{
      reject(error);
    });
  });
}


const treatShopData = (data)=>{
  data.tax = data.tax || 0;
};