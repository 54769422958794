// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brandy-container {
  margin-top: 20px;
  max-width: 100%;
  width: 100%; }
  .brandy-container .ant-col-spin {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh; }
  .brandy-container .button {
    width: 150px; }
  .brandy-container .ant-row {
    width: 100%; }
    .brandy-container .ant-row .ant-col {
      width: 100%; }
      .brandy-container .ant-row .ant-col .ant-card {
        width: 100%; }
        .brandy-container .ant-row .ant-col .ant-card .ant-card-body {
          padding: 24px 10px 24px 10px; }
          .brandy-container .ant-row .ant-col .ant-card .ant-card-body .ant-card-meta-detail > div:not(:last-child) {
            font-size: 14px; }
          .brandy-container .ant-row .ant-col .ant-card .ant-card-body .ant-card-meta-description {
            color: var(--dark);
            font-size: 14px; }
        .brandy-container .ant-row .ant-col .ant-card .icon-center {
          position: absolute;
          top: 8px;
          right: 10px;
          display: flex; }
    .brandy-container .ant-row .ant-row {
      width: 100%;
      display: flex;
      justify-content: center; }
`, "",{"version":3,"sources":["webpack://./src/assets/scss/components/brand.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,WAAW,EAAA;EAHb;IAMI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY,EAAA;EAThB;IAaI,YAAY,EAAA;EAbhB;IAiBI,WAAW,EAAA;IAjBf;MAoBM,WAAW,EAAA;MApBjB;QA2CQ,WAAW,EAAA;QA3CnB;UAwBU,4BAA4B,EAAA;UAxBtC;YA2BY,eAAe,EAAA;UA3B3B;YA+BY,kBAAkB;YAClB,eAAe,EAAA;QAhC3B;UAqCU,kBAAkB;UAClB,QAAQ;UACR,WAAW;UACX,aAAa,EAAA;IAxCvB;MAgDM,WAAW;MACX,aAAa;MACb,uBAAuB,EAAA","sourcesContent":[".brandy-container {\n  margin-top: 20px;\n  max-width: 100%;\n  width: 100%;\n\n  .ant-col-spin {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 20vh;\n  }\n\n  .button {\n    width: 150px;\n  }\n\n  .ant-row {\n    width: 100%;\n\n    .ant-col {\n      width: 100%;\n\n      .ant-card {\n        .ant-card-body {\n          padding: 24px 10px 24px 10px;\n\n          .ant-card-meta-detail > div:not(:last-child) {\n            font-size: 14px;\n          }\n\n          .ant-card-meta-description {\n            color: var(--dark);\n            font-size: 14px;\n          }\n        }\n\n        .icon-center {\n          position: absolute;\n          top: 8px;\n          right: 10px;\n          display: flex;\n        }\n\n        width: 100%;\n      }\n    }\n\n    .ant-row {\n      width: 100%;\n      display: flex;\n      justify-content: center;\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
