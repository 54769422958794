// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-access-info .project-card {
  display: flex !important;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 15px 0;
  justify-content: space-between;
  align-items: center; }
  .project-access-info .project-card .project-name {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    margin: 0; }
  .project-access-info .project-card .project-version {
    font-size: 18px;
    margin: 0; }
  .project-access-info .project-card .project-link {
    display: flex;
    align-items: center; }
    .project-access-info .project-card .project-link span {
      margin-right: 10px; }
`, "",{"version":3,"sources":["webpack://./src/assets/scss/page/project-access-info.scss"],"names":[],"mappings":"AAAA;EAEI,wBAAwB;EACxB,YAAY;EACZ,2CAA2C;EAC3C,eAAe;EACf,8BAA8B;EAC9B,mBAAmB,EAAA;EAPvB;IAUM,yBAAyB;IACzB,eAAe;IACf,iBAAiB;IACjB,SAAS,EAAA;EAbf;IAiBM,eAAe;IACf,SAAS,EAAA;EAlBf;IAsBM,aAAa;IACb,mBAAmB,EAAA;IAvBzB;MA0BQ,kBAAkB,EAAA","sourcesContent":[".project-access-info {\n  .project-card {\n    display: flex !important;\n    border: none;\n    border-bottom: 1px solid rgba(0, 0, 0, 0.2);\n    padding: 15px 0;\n    justify-content: space-between;\n    align-items: center;\n\n    .project-name {\n      text-transform: uppercase;\n      font-size: 18px;\n      font-weight: bold;\n      margin: 0;\n    }\n\n    .project-version {\n      font-size: 18px;\n      margin: 0;\n    }\n\n    .project-link {\n      display: flex;\n      align-items: center;\n\n      span {\n        margin-right: 10px;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
